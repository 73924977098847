.section-benefits {
  position: relative;
  .row {
    margin-bottom: -24px;
  }
  .img-bg {
    @include stretch;
    z-index: -1;
    top: -312px;
    bottom: -25px;
    background-position: center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    @include breakpoint-down(md) {
      top: -130px;
      bottom: -130px;
    }
  }
}
.item-benefits {
  display: flex;
  margin-bottom: 24px;
  @include breakpoint-down(md) {
    justify-content: center;
  }
  &:nth-child(even) {
    justify-content: center;
  }
  &:nth-child(3n+3) {
    justify-content: flex-end;
    @include breakpoint-down(md) {
      justify-content: center;
    }
  }
  &-wrap {
    background: #FFFFFF;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    max-width: 276px;
    padding: 24px;
    width: 100%;
  }
  .item-icon {
    margin-bottom: 24px;
  }
  .item-title {
    @include title-2;
    margin-bottom: 16px;
  }
  .item-desc {
    color: #999;
    // margin-bottom: 32px;
    font-weight: bold;
  }
  .item-more {
    @include text-small;
    color: #999;
  }
}
