.container {
	@include create-container();
	@include create-container-max-widths();
}

// Fluid container (full width)
.container-fluid {
	@include create-container();
}

// Row
.row {
	@include create-row();
}

// no gutters
//
// Remove margin then the horizontal padding
.no-gutters {
  margin-right: 0;
  margin-left: 0;
  > .col,
  > [class*="col"],
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

// Columns
@include create-grid-columns();
