html,
body,
.wrapper {
	height: 100%;
  scroll-behavior: smooth;
}

body {
	color: $text-color;
	background-color: $body-bg;
	max-width: 100%;
}

.wrapper-in { // wrapper
	position: relative;
	display: flex;
	min-height: 100vh;
	flex-direction: column;
}

.content {
	overflow: hidden;
	flex: 1;
}

.burger--icon {
  @include burger(18px, 2px, 3px, #000);
  // pointer-events: none;
}
.btn--burger {
  display: inline-flex;
  width: 40px;
  height: 24px;
  align-items: center;
  justify-content: flex-end;
  float: right;
  .sidebar--opened & {
    .burger--icon {
      @include burger-to-cross;
      &::before,
      &::after {
        background-color: #000;
      }
    }
  }
}
