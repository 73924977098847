.methods-list {
  margin-bottom: -24px;
}
.item-methods {
  margin-bottom: 24px;
  .item-media {
    margin-bottom: 24px;
  }
  .item-title {
    @include title-3;
    margin-bottom: 8px;
  }
  .item-desc {
    color: rgba(0, 0, 0, 0.4);
  }
}
