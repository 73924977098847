
.section {
  padding: 72px 0;
  @include breakpoint-down(md) {
    padding: 32px 0;
  }
  &-head {
    margin-bottom: 40px;
    text-align: center;
    @include breakpoint-down(sm) {
      margin-bottom: 24px;
    }
  }
  &-title {
    @include title-2;
  }
}

.page--mainpage {
  padding-top: 172px;
  @include breakpoint-down(md) {
    padding-top: 80px;
  }
}
.list-checked {
  @include list-unstyled;
  li {
    position: relative;
    padding-left: 48px;
    line-height: 24px;
    @extend .icon-check-circle;
    &:before {
      font-family: 'iconfont';
      position: absolute;
      font-size: 24px;
      color: $brand-primary;
      left: 0;
      top: 0;
    }
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}
