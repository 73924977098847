.nav-main {
	ul {
    margin-bottom: 0;
  }
  a {
    color: #000;
    &:hover {
      color: $brand-primary;
    }
  }
  li {
    @include breakpoint-down(md) {
      display: block;
      @include text-large;
    }
    &:not(:last-child) {
      margin-right: 55px;
      @include breakpoint-down(md) {
        margin-right: 0;
        margin-bottom: 8px;
      }
    }
  }
}
