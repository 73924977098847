.section-form {
  .section-wrap {
    background: #F6F6F6;
    border-radius: 16px;
    padding: 40px 0;
    @include breakpoint-down(md) {
      padding: 0 16px 8px;
    }
  }
  .section-content-wrap {
    & > .row {
      align-items: center;
    }
  }
  .media {
    &-wrap {
      margin-left: -12px;
      @include breakpoint-down(md) {
        margin-bottom: 16px;
        margin-left: 0;
        margin-top: -20px;
      }
    }
    img {
      @include breakpoint-down(md) {
        max-height: 200px;
        margin: 0 auto;
      }
    }
  }
  .list-checked {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
    @include breakpoint-down(md) {
      margin: 0;
    }
    li {
      width: 50%;
      padding-left: 60px;
      padding-right: 12px;
      margin-bottom: 24px;
      @include breakpoint-down(md) {
        padding-left: 40px;
        padding-right: 0;
        width: 100%;
      }
      &::before {
        left: 12px;
        @include breakpoint-down(md) {
          left: 0;
        }
      }
    }
  }
  .title {
    @include title-3;
    margin-bottom: 24px;
    text-align: center;
    @include breakpoint-down(md) {
      @include text-large;
      font-weight: bold;
    }
  }
  .section-content-right {
    padding-right: 24px;
  }
}
