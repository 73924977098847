

@mixin title-1 {
  font-weight: bold;
  font-size: 56px;
  line-height: 72px;
  @include breakpoint-down(md) {
    font-size: 32px;
    line-height: 32px;
  }
}
@mixin title-2 {
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  @include breakpoint-down(md) {
    font-size: 24px;
    line-height: 32px;
  }
}
@mixin title-3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  @include breakpoint-down(md) {
    font-size: 20px;
    line-height: 32px;
  }
}
@mixin text-large {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

@mixin text-normal {
  font-size: 14px;
  line-height: 24px;
}

@mixin text-small {
  font-size: 12px;
  line-height: 18px;
}
