// how to use burger
/*

	Example :
	.burger-icon {
		@include burger(25px, 3px, 5px, #222);
	}
	.circle.is-active .burger-icon {
		@include burger-to-cross;
	}

*/

@mixin burger($width: 30px, $height: 5px, $gutter: 3px, $color: #000, $border-radius: 0, $transition-duration: .3s) {
	$burger-height: $height !global;
	$burger-gutter: $gutter !global;

	position: relative;
	margin-top: $height + $gutter;
	margin-bottom: $height + $gutter;
	user-select: none;
  pointer-events: none;

	// 1. Fixes jagged edges in Firefox, see issue #10.
	&, &::before, &::after {
		display: block;
		width: $width;
		height: $height;
		background-color: $color;
		outline: 1px solid transparent; // 1
		@if $border-radius != 0 {
				border-radius: $border-radius;
		}

		transition-property: background-color, transform;

		transition-duration: $transition-duration;
	}

	&::before, &::after {
			position: absolute;
			content: "";
	}

	&::before {
			top: -($height + $gutter);
	}

	&::after {
			top: $height + $gutter;
	}
}


// Select parts of the burger
@mixin burger-parts {
	&, &::before, &::after {
		@content;
	}
}

@mixin burger-top {
	&::before {
		@content;
	}
}

@mixin burger-middle {
	& {
		@content;
	}
}

@mixin burger-bottom {
	&::after {
		@content;
	}
}


// Burger animations
@mixin burger-to-cross($color: auto) {
	& {
		background-color: transparent;
	}
	@if ($color != auto) {
		&::before, &::after {
			background-color: $color;
		}
	}
	&::before {
		transform: translateY($burger-gutter + $burger-height) rotate(45deg);
	}
	&::after {
		transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
	}
}
