.section-hodden {
  position: relative;
  .img-bg {
    @include stretch;
    z-index: -1;
    top: -99px;
    bottom: -86px;
    background-position: center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    @include breakpoint-down(md) {
      top: -95px;
      bottom: -34px;
    }
  }
  .section-wrap {
    @include breakpoint-down(sm) {
      background: #FFFFFF;
      box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08);
      border-radius: 16px;
      padding: 32px 16px 16px;
    }
  }
  .section-content {
    @include breakpoint-up(sm) {
      background: #FFFFFF;
      box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08);
      border-radius: 16px;
      padding: 24px 0;
    }
    &-left {
      display: flex;
      padding-left: 24px;
      height: 100%;
      align-items: center;
      max-width: 434px;
      @include breakpoint-down(sm) {
        padding-left: 0;
        max-width: 100%;
        margin-bottom: 40px;
        height: auto;
      }
    }
    .list-checked {
      margin-bottom: 0;
    }
    &-right {
      padding-right: 24px;
      @include breakpoint-down(sm) {
        padding-right: 0;
      }
      &-wrap {
        padding: 85px 64px;
        background: $brand-primary;
        border-radius: 12px;
        position: relative;
        @include breakpoint-down(sm) {
          padding: 32px 16px;
        }
      }
    }
    .item-desc {
      text-transform: uppercase;
      @include title-3;
    }
    .item-sum {
      font-size: 88px;
      line-height: 88px;
      font-weight: 600;
      color: #fff;
      @include breakpoint-down(sm) {
        font-size: 68px;
        line-height: 68px;
      }
    }
    .item-badge {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%,-50%);
      background: #F33A71;
      border-radius: 50%;
      width: 96px;
      height: 96px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #fff;
      text-align: center;
      line-height: 12px;
      @include breakpoint-down(sm) {
        transform: translate(25%,-50%);
      }
      span {
        font-weight: bold;
        font-size: 48px;
        line-height: 24px;
        display: block;
        margin-bottom: 7px;
      }
    }
  }
}
