.promo-block {
  margin-bottom: 72px;
  @include breakpoint-down(md) {
    margin-bottom: 32px;
    [class^="col-md-"] {
      &:last-child {
        order: -1;
      }
    }
    .media {
      margin-bottom: 24px;
    }
  }
  .title {
    @include title-1;
    margin-bottom: 24px;
  }
  .item-list {
    @include list-unstyled;
    margin-bottom: 40px;
  }
  .item-btns {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    .btn-primary {
      margin-right: 24px;
    }
    span {
      color: $brand-primary;
    }
  }
}
