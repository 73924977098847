$sidebar-width: 300px;

.sidebar {
  width: $sidebar-width;
  background: #f2f2f2;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  transform: translateX(-100%);
  transition: transform .4s ease;
  &.is--opened {
    transform: translateX(0);
  }
  .sidebar--wrapper {
    @include spacing-level(red, both, padding);
  }
}
