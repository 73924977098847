.section-carousel {
  .section-content {
    position: relative;
  }
  .item-carousel {
    background: #FFFFFF;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    display: flex;
    color: #505050;
    height: 100%;
    @include breakpoint-down(sm) {
      max-width: 301px;
      margin: 0 auto;
      display: block;
      padding: 24px 16px 16px;
      text-align: center;
    }
    .item-media {
      width: 49.2%;
      position: relative;
      @include breakpoint-down(sm) {
        width: 100%;
        margin: 0 auto 16px;
        width: 80px;
        height: 80px;
      }
      .img {
        @include stretch;
        background-size: cover;
        background-position: center;
        border-radius: 16px 0 0 16px;
        @include breakpoint-down(sm) {
          border-radius: 50%;
        }
      }
    }
    .item-content {
      width: 50.8%;
      padding: 24px 16px;
      @include breakpoint-down(sm) {
        width: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
      }
    }
    .item-desc {
      @include text-small;
      font-style: italic;
      margin-bottom: 18px;
      @include breakpoint-down(sm) {
        order: 3;
        margin-bottom: 0;
      }
    }
    .item-title {
      @include text-small;
      font-weight: bold;
      @include breakpoint-down(sm) {
        order: 0;
        margin-bottom: 8px;
      }
    }
    .item-meta {
      @include text-small;
      font-weight: 500;
      @include breakpoint-down(sm) {
        margin-bottom: 16px;
        order: 1;
      }
    }
    .item-rating {
      margin-bottom: 18px;
      @include breakpoint-down(sm) {
        order: 2;
        margin: 0 auto 24px;
      }
    }
  }
  .swiper-slide {
    height: auto;
  }
  .swiper-container {
    padding-bottom: 40px;
    @include breakpoint-down(xs) {
      overflow: initial;
    }
  }
  .section-carousel-controls {
    @include breakpoint-up(md) {
      display: none;
    }
  }
  .section-footer {
    text-align: center;
    .media {
      margin-bottom: 16px;
      img {
        max-height: 40px;
        width: 100%;
      }
      &-trustpilot {
        img {
          max-height: 40px;
        }
      }
    }
    .desc {
      color: $gray;
      margin-bottom: 16px;
    }
    a {
      display: block;
    }
  }
}
