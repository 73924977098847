.footer {
  background-color: #4bb9aa;
  padding: 40px 0;
  @include breakpoint-down(md) {
    padding: 24px 0;
  }
  [class^="col-lg-"] {
    @include breakpoint-down(md) {
      margin-bottom: 16px;
    }
  }
  &-sup {
    margin-bottom: 28px;
    @include breakpoint-down(md) {
      margin-bottom: 16px;
    }
  }
  .flex-bottom {
    display: flex;
    align-items: flex-end;
    padding-bottom: 8px;
  }
  &-bottom {
    padding-top: 40px;
    color: rgba(255, 255, 255, 0.56);
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.444444px;
    @include breakpoint-down(sm) {
      padding-top: 0px;
    }
    a {
      color: inherit;
    }
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .footer-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Poppins';
    color: #ffffff;
    margin-bottom: 8px;
  }
  .footer-list {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
    @include text-small;
    li {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    a {
      opacity: 0.8;
      color: #ffffff;
      // @include text-caption;
    }
    &-link {
      list-style: none;
      margin-bottom: 0;
      padding-left: 0;
      font-weight: bold;
      li {
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
      a {
        display: flex;
        align-items: center;
        color: #ffffff;
        // @include text-caption;
        .icon {
          font-size: 20px;
          margin-right: 10px;
        }
      }
    }
  }
}
