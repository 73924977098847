// Grid
@mixin create-container() {
	width: 100%;
	padding-right: ($gutter-width / 2);
	padding-left: ($gutter-width / 2);
	margin-right: auto;
	margin-left: auto;
}

@mixin create-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
	@each $breakpoint, $container-max-width in $max-widths {
	  @include breakpoint-up($breakpoint, $breakpoints) {
		  max-width: $container-max-width;
	  }
	}
}

@function mapReverse($map) {
  $result: null;

  @if type-of($map) == "map" {
      $keys: map-keys($map);
      $map-reversed: ();

      @for $i from length($keys) through 1 {
          $map-reversed: map-merge(
              $map-reversed,
              (nth($keys, $i): map-get($map, nth($keys, $i)))
          );
      }

      @if type-of($map-reversed) == "map" {
          $result: $map-reversed;
      } @else {
          @warn 'There was an error reversing the order of "#{$map}"';
      }
  } @else {
      @warn '"#{$map}" is not a valid map';
  }

  @return $result;
}

@mixin spacing-level($color, $direction: both, $type: margin) {
  $list-margins: map-get($levels, $color);
  $index: -1;

  @each $key, $value in mapReverse($grid-breakpoints) {

    $margin: nth($list-margins, $index);

    @include breakpoint-down($key) {
      @if($direction == both) {
        #{$type}: $margin;
      } @else if($direction == top or $direction == right or $direction == bottom or $direction == left) {
        #{$type}-#{$direction}: $margin;
      } @else {
        @error "No direction can be found";
      }
    }

    $index: $index - 1;
  }
}

@mixin font-size($name) {
  $list-by-name: map-get($font-sizes, unquote($name));
  $current-font-sizes: ();
  $current-line-height: nth($list-by-name, -1);
  @for $i from 1 through length($list-by-name) - 1 {
    $current-font-sizes: append($current-font-sizes, nth($list-by-name, $i));
  }

  $index: -1;
  @each $key, $value in mapReverse($grid-breakpoints) {

    $size: nth($current-font-sizes, $index);
    $line-height: $current-line-height;
    @if(length($current-line-height) != 1) {
      $line-height: nth($current-line-height, $index);
    }

    @include breakpoint-down($key) {
      font-size: $size;
      line-height: $line-height;
    }

    $index: $index - 1;
  }

}

@import 'grid-flex';
