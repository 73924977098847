.tabs {
  .tabs-header {
    @include spacing-level(blue, bottom);
    button {
      @include font-size('nav');
      padding: 10px 20px;
      border-radius: 5px;
      &.isActive {
        background: #000;
        color: #fff;
      }
    }
  }
  .tabs-body {
    @include spacing-level(green, both, padding);
    border: 1px solid #f7f7f7;
    border-radius: 5px;
  }
}
