.section-partners {
  .partners-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;;
    margin: 0 -24px -24px;
    @include breakpoint-down(md) {
      margin: 0 -8px -8px;
    }
  }
  .item-partners {
    padding: 0 24px 24px;
    @include breakpoint-down(md) {
      padding: 0 8px 8px;
      display: inline-block;
      text-align: center;
    }
  }
  .img-fluid {
    @include breakpoint-down(md) {
      width: 75%;
      display: inline-block;
    }
  }
}
