.section-fast {
  .item-title {
    @include title-2;
    margin-bottom: 16px;
    @include breakpoint-down(md) {
      display: none;
      margin-bottom: 8px;
      text-align: center;
    }
    &-sm {
      display: none;
      @include breakpoint-down(md) {
        display: block;
        text-align: center;
      }
    }
  }
  .media {
    @include breakpoint-down(md) {
      margin-bottom: 16px;
      img {
        margin: 0 auto;
      }
    }
    &-2 {
      max-width: 325px;
      @include breakpoint-down(md) {
        margin: 0 auto 16px;
      }
    }
  }
  .item-desc {
    margin-bottom: 40px;
    font-weight: 500;
    @include breakpoint-down(md) {
      display: none;
      margin-bottom: 16px;
      text-align: center;
    }
    &-sm {
      display: none;
      @include breakpoint-down(md) {
        display: block;
      }
    }
  }
  .item-list {
    color: #000;
    font-weight: bold;
    @include list-unstyled;
    li {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
    &-icon {
      margin-right: 24px;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
}
