$btn-font-size: $font-size-base !default;
$btn-line-height: $font-size-base !default;
$btn-box-shadow: inset 0 3px 5px rgba(black,.125);
$btn-disabled-opacity: .65;
$btn-border-width: 1px !default;
// *************************************************************************

.btn {
	display: inline-block;
	margin-bottom: 0;
	font-weight: normal;
	text-align: center;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none;
	border: $btn-border-width solid transparent;
	white-space: nowrap;
	font-size: $btn-font-size;
	line-height: $btn-line-height;
	user-select: none;
	transition-property: box-shadow, background-color;
	transition-duration: .2s;
	outline: none !important;
	text-decoration: none !important;
	padding: 6px 12px;

	&:active,
	&.active {
		box-shadow: $btn-box-shadow;
	}

	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		cursor: not-allowed;
		opacity: $btn-disabled-opacity;
		box-shadow: none;
	}
  &.btn-primary {
    background: $brand-primary;
    text-transform: uppercase;
    padding: 11px 24px;
    box-shadow: 0px 3px 5px rgba(75, 184, 169, 0.24);
    border-radius: 8px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  &.btn-secondary {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.444444px;
    text-transform: uppercase;
    background: #FACF1A;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 12px 24px;
    color: #000;
  }
}

a.btn {
	&.disabled,
	fieldset[disabled] & {
		pointer-events: none;
	}
}

.btn-block {
	display: block;
	width: 100%;
}

input[type="submit"],
input[type="reset"],
input[type="button"] {
	&.btn-block {
		width: 100%;
	}
}
